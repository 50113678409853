<template>
    <div class="d-flex justify-content-end flex-grow-1">
    </div>
  </template>
  
  <script>
  import { Actions } from "@/store/enums/StoreEnums";
  import { useStore } from "vuex";
  import { computed } from "vue";
  import { ElMessage } from "element-plus";
  import TENANT_SETTING_MODEL from "@/core/data/tenantSettingModel";
  export default {
    name: "general-setting-toolbar",
    setup() {
      const store = useStore();
      const save = () => {
  
        try {
          if(store.getters.getFormThemeVal) localStorage.setItem('mode', store.getters.getFormThemeVal.toLowerCase())
          if(store.getters.getFormLangVal) localStorage.setItem('locale', store.getters.getFormLangVal.toLowerCase())
          ElMessage({
            message: 'Succes change Theme & Language',
            type: "success",
          });
        } catch (error) {
          ElMessage({
                message: 'Failed change Theme & Language',
                type: "error",
              });
        }
      };
      const isSubmitting = computed(() => store.getters.gsIsSubmitting);
      return {
        isSubmitting,
        save,
      };
    },
  };
  </script>
  
  <style scoped></style>
  