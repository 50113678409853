
import { computed, defineComponent } from 'vue';
import { Actions } from '@/store/enums/StoreEnums';
import { useStore } from 'vuex';
import { checkPermission } from '@/core/helpers/helps';
import { DashLinkDrawerMode } from '@/store/modules/DashboardLinkModule';

import * as storeTutorial from '@/store/enums/StoreTutorialEnums';
import { Tutorial } from '@/store/enums/interface_option/tutorial';

export default defineComponent({
  name: 'dashboard-link-toolbar',
  setup() {
    const store = useStore();
    const keyword = computed({
      get: () => store.getters.usersFilter.name,
      set: (val) => store.dispatch(Actions.UPDATE_USERS_KEYWORD, val),
    });

    function search() {
      store.dispatch(Actions.UPDATE_USERS_PAGE, 1);
    }

    function openDrawer() {
      store.dispatch(Actions.UPDATE_DASHBOARD_LINK_DRAWER_STATE, true);
      store
        .dispatch(
          Actions.UPDATE_DASHBOARD_LINK_DRAWER_MODE,
          DashLinkDrawerMode.ADD
        )
        .then(() => {
          store.commit(storeTutorial.Mutations.FILTER_STEP, {
            name: 'dashboard-link/add-modal',
            bool: true,
          } as Tutorial.localStorage);
        });
    }

    return {
      openDrawer,
      search,
      keyword,
      checkPermission,
    };
  },
});
