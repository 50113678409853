<template>
  <div class="row flex-grow-1">
    <div class="col col-sm-4">
      <el-input
        v-model="keyword"
        :label="$t('common.search')"
        :placeholder="$t('common.search')"
        @keyup="search"
      >
        <template #prefix>
          <i class="fas fa-search ms-2" />
        </template>
      </el-input>
    </div>
    <div class="col text-end">
      <button
        v-if="checkPermissionUpdate('setting-master', 'setting.master.create')"
        class="btn mx-3"
        @click="openDrawer()"
        style="background: #b31e72; color: white"
      >
        <span> 
          {{$t('common.add') }}
          {{ assetInfo }}
        </span>
        <!-- {{ $t('earningStatusPage.addEarningStatus') }} -->
      </button>
    </div>
  </div>
</template>

<script>
import { checkPermissionUpdate } from "@/core/helpers/helps";
import { useStore } from 'vuex';
import { computed, ref } from 'vue';
import { Actions } from '@/store/enums/StoreEnums';

export default {
  name: 'earning-status-toolbar',
  setup() {
    const store = useStore();
    const keyword = computed({
      get: () => store.getters.usersFilter.name,
      set: (val) => store.dispatch(Actions.UPDATE_USERS_KEYWORD, val),
    });
    const awaitingSearch = ref();

    function search() {
      if (awaitingSearch.value) {
        clearTimeout(awaitingSearch.value);
        awaitingSearch.value = 0;
      }
      awaitingSearch.value = setTimeout(() => {
        const trimFilter = store.getters.usersFilter;
        store.dispatch(Actions.UPDATE_USERS_PAGE, 1);
        store.dispatch(Actions.GET_USER_LIST, {
          ...trimFilter,
          name: trimFilter.name.replace(/\s+/g, ' ').trim(),
        });
      }, 1000);
    }

    function openDrawer(member, mode = 'add') {
      store.dispatch(Actions.UPDATE_EARNING_STATUS_DRAWER, true);
      store.dispatch(Actions.UPDATE_EARNING_STATUS_DRAWER_MODE, mode);
    }

    const assetInfo = computed(() => {
      return store.getters.getStatusCategory.name
    })

    return {
      openDrawer,
      checkPermissionUpdate,
      search,
      keyword,
      assetInfo
    };
  },
};
</script>

<style scoped></style>
