<template>
  <div class="row flex-grow-1">
    <div class="col col-sm-4">
    </div>
    <div class="col text-end">
      <button
        v-if="checkPermissionUpdate('setting-env','setting.channel.create')"
        class="btn mx-3"
        @click="openDrawer()"
        style="background: #b31e72; color: white"
      >
        {{ $t('channelPage.addChannel') }}
      </button>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed, ref } from 'vue';
import { Actions } from '@/store/enums/StoreEnums';
import { checkPermissionUpdate } from '@/core/helpers/helps';

export default {
  name: 'channel-toolbar',
  setup() {
    const store = useStore();
    const keyword = computed({
      get: () => store.getters.usersFilter.name,
      set: (val) => store.dispatch(Actions.UPDATE_USERS_KEYWORD, val),
    });
    const awaitingSearch = ref();

    function search() {
      if (awaitingSearch.value) {
        clearTimeout(awaitingSearch.value);
        awaitingSearch.value = 0;
      }
      awaitingSearch.value = setTimeout(() => {
        const trimFilter = store.getters.usersFilter;
        store.dispatch(Actions.UPDATE_USERS_PAGE, 1);
        store.dispatch(Actions.GET_USER_LIST, {
          ...trimFilter,
          name: trimFilter.name.replace(/\s+/g, ' ').trim(),
        });
      }, 1000);
    }

    function openDrawer(member, mode = 'add') {
      store.dispatch(Actions.UPDATE_CHANNEL_DRAWER, true);
      store.dispatch(Actions.UPDATE_CHANNEL_DRAWER_MODE, mode);
    }

    return {
      openDrawer,
      search,
      keyword,
      checkPermissionUpdate
    };
  },
};
</script>

<style scoped></style>
