<template>
  <div v-if="isActive.page_permissions[7].is_active" class="d-flex justify-content-end flex-grow-1">
    <button class="btn btn-primary" :disabled="isSubmitting" @click="save">
      {{ $t('common.save') }}
    </button>
  </div>
</template>

<script>
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { computed } from "vue";
import { ElMessage } from "element-plus";
import TENANT_SETTING_MODEL from "@/core/data/tenantSettingModel";
export default {
  name: "general-setting-toolbar",
  setup() {
    const store = useStore();
    const save = () => {

      try {
        if(store.getters.getFormThemeVal) localStorage.setItem('mode', store.getters.getFormThemeVal.toLowerCase())
        if(store.getters.getFormLangVal) localStorage.setItem('locale', store.getters.getFormLangVal.toLowerCase())
        ElMessage({
          message: 'Succes change Theme & Language',
          type: "success",
        });
      } catch (error) {
        ElMessage({
              message: 'Failed change Theme & Language',
              type: "error",
            });
      }
      location.reload();
      

      // store
      //   .dispatch(Actions.UPDATE_GENERAL_SETTING)
      //   .then((result) => {
      //     if (result.success) {
      //       ElMessage({
      //         message: result.msg,
      //         type: "success",
      //       });
      //     }
      //   })
      //   .then(() => store.dispatch(Actions.VERIFY_AUTH))
      //   .then(({ data }) => {
      //     // SET APP LOCALE
      //     const { tenant_setting } = data;
      //     if (tenant_setting[TENANT_SETTING_MODEL.lang]) {
      //       const selectedLang = tenant_setting[TENANT_SETTING_MODEL.lang].find(
      //         (setting) => setting.is_default
      //       );
      //       if (selectedLang) {
      //         store.dispatch(
      //           Actions.UPDATE_LOCALE,
      //           selectedLang.models.lang.toLowerCase()
      //         );
      //       }
      //     }
      //     if (tenant_setting[TENANT_SETTING_MODEL.theme]) {
      //       const selectedTheme = tenant_setting[
      //         TENANT_SETTING_MODEL.theme
      //       ].find((setting) => setting.is_default);
      //       if (selectedTheme) {
      //         localStorage.setItem(
      //           "mode",
      //           selectedTheme.models.name.toLowerCase()
      //         );
      //         window.location.reload();
      //       }
      //     }
      //   })
      //   .catch((error) => {
      //     if (error.lang || error.theme) {
      //       Object.keys(error).forEach((key, index) => {
      //         setTimeout(() => {
      //           ElMessage({
      //             message: error[key].toString(),
      //             type: "error",
      //           });
      //         }, index * 3500);
      //       });
      //     } else {
      //       ElMessage({
      //         message: error,
      //         type: "error",
      //       });
      //     }
      //     store.dispatch(Actions.UPDATE_GS_FORM_THEME, null);
      //     store.dispatch(Actions.UPDATE_GS_FORM_LANG, null);
      //   });
    };
    const isSubmitting = computed(() => store.getters.gsIsSubmitting);
    const data = window.localStorage.getItem("role")
    const isActive = JSON.parse(data)
    return {
      isSubmitting,
      save,
      isActive
    };
  },
};
</script>

<style scoped></style>
