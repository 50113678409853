
import GeneralSetting from '@/components/pages/setting/toolbar/GeneralSetting.vue';
import Members from '@/components/pages/setting/toolbar/Members.vue';
import RoleAndPermission from '@/components/pages/setting/toolbar/RoleAndPermission.vue';
import { computed, defineComponent, ref, watch } from 'vue';
import { toolbarWidthFluid } from '@/core/helpers/config';
import { useRoute } from 'vue-router';
import DashboardLinkToolbar from '@/components/pages/setting/toolbar/DashboardLink.vue';
import EarningStatusToolbar from '@/components/pages/setting/toolbar/EarningStatusToolbar.vue';
import ChannelToolbar from '@/components/pages/setting/toolbar/Channel.vue';
import WorkspaceToolbar from '@/components/pages/setting/toolbar/Workspace.vue';
import CategoryToolbar from '@/components/pages/setting/toolbar/Category.vue';
import SettingsToolbar from '@/components/pages/setting/toolbar/Settings.vue';
import AccessToken from '@/components/pages/setting/toolbar/AccessToken.vue';


export default defineComponent({
  name: 'toolbar-setting',
  components: {
    DashboardLinkToolbar,
    'general-setting-toolbar': GeneralSetting,
    'members-toolbar': Members,
    'role-and-permission-toolbar': RoleAndPermission,
    EarningStatusToolbar,
    ChannelToolbar,
    WorkspaceToolbar,
    CategoryToolbar,
    SettingsToolbar,
    AccessToken,
  },
  setup() {
    
    const catPrj = computed(() => {
      return route.path;
    });
    const route = useRoute();

    const toolbarkey = ref(0);

    watch(() => route.path, () => {
      toolbarkey.value++
    }, { immediate: true });

    return {
      route,
      toolbarWidthFluid,
      catPrj,
      toolbarkey
    };
  },
});
