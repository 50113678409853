
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { ScrollComponent } from "@/assets/ts/components";
import { MenuComponent } from "@/assets/ts/components";
import { asideMenuIcons } from "@/core/helpers/config";
import SettingMenuConfig from "../../core/config/SettingMenuConfig";
import { checkAccess, checkPermissionUpdate } from "@/core/helpers/helps";

export default defineComponent({
  name: "menu-setting",
  components: {},
  setup() {
    const { t, te } = useI18n();
    const route = useRoute();
    const scrollElRef = ref<null | HTMLElement>(null);

    onMounted(() => {
      ScrollComponent.reinitialization();
      MenuComponent.reinitialization();
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const changeMode = (mode) => {
      localStorage.setItem("mode", mode);
      window.location.reload();
    };

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    return {
      translate,
      changeMode,
      hasActiveChildren,
      SettingMenuConfig,
      asideMenuIcons,
      checkAccess,
      checkPermissionUpdate,
    };
  },
});
