import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  "data-kt-swapper": "true",
  "data-kt-swapper-mode": "prepend",
  "data-kt-swapper-parent": "{lg: '#kt_toolbar_container', 'lg': '#kt_toolbar_container'}",
  class: "page-title d-flex align-items-center w-100 flex-wrap mb-5 mb-lg-0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_general_setting_toolbar = _resolveComponent("general-setting-toolbar")!
  const _component_members_toolbar = _resolveComponent("members-toolbar")!
  const _component_role_and_permission_toolbar = _resolveComponent("role-and-permission-toolbar")!
  const _component_dashboard_link_toolbar = _resolveComponent("dashboard-link-toolbar")!
  const _component_earning_status_toolbar = _resolveComponent("earning-status-toolbar")!
  const _component_ChannelToolbar = _resolveComponent("ChannelToolbar")!
  const _component_WorkspaceToolbar = _resolveComponent("WorkspaceToolbar")!
  const _component_CategoryToolbar = _resolveComponent("CategoryToolbar")!
  const _component_SettingsToolbar = _resolveComponent("SettingsToolbar")!
  const _component_AccessToken = _resolveComponent("AccessToken")!

  return (_openBlock(), _createElementBlock("div", {
    class: "toolbar",
    id: "kt_toolbar",
    key: _ctx.toolbarkey
  }, [
    _createElementVNode("div", {
      id: "kt_toolbar_container",
      class: _normalizeClass([{
        'container-fluid': _ctx.toolbarWidthFluid,
        'container-xxl': !_ctx.toolbarWidthFluid,
      }, "d-flex flex-stack"])
    }, [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.route.name === 'general-setting')
          ? (_openBlock(), _createBlock(_component_general_setting_toolbar, { key: 0 }))
          : _createCommentVNode("", true),
        (_ctx.route.name === 'members')
          ? (_openBlock(), _createBlock(_component_members_toolbar, { key: 1 }))
          : _createCommentVNode("", true),
        (_ctx.route.name === 'role-and-permission')
          ? (_openBlock(), _createBlock(_component_role_and_permission_toolbar, { key: 2 }))
          : _createCommentVNode("", true),
        (_ctx.route.name === 'master-data-dashboard-link')
          ? (_openBlock(), _createBlock(_component_dashboard_link_toolbar, { key: 3 }))
          : _createCommentVNode("", true),
        (_ctx.route.name === 'category-value')
          ? (_openBlock(), _createBlock(_component_earning_status_toolbar, { key: 4 }))
          : _createCommentVNode("", true),
        (_ctx.route.name === 'channel-environtment')
          ? (_openBlock(), _createBlock(_component_ChannelToolbar, { key: 5 }))
          : _createCommentVNode("", true),
        (_ctx.route.name === 'workspace-environtment')
          ? (_openBlock(), _createBlock(_component_WorkspaceToolbar, { key: 6 }))
          : _createCommentVNode("", true),
        (_ctx.route.name === 'asset-category')
          ? (_openBlock(), _createBlock(_component_CategoryToolbar, { key: 7 }))
          : _createCommentVNode("", true),
        (_ctx.route.name === 'category-currency')
          ? (_openBlock(), _createBlock(_component_SettingsToolbar, { key: 8 }))
          : _createCommentVNode("", true),
        (_ctx.route.name === 'category-document')
          ? (_openBlock(), _createBlock(_component_SettingsToolbar, { key: 9 }))
          : _createCommentVNode("", true),
        (_ctx.route.name === 'admin-access-token')
          ? (_openBlock(), _createBlock(_component_AccessToken, { key: 10 }))
          : _createCommentVNode("", true)
      ])
    ], 2)
  ]))
}