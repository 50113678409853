<template>
  <div class="row flex-grow-1">
    <div class="col col-sm-4">
      <el-input
        v-model="keyword"
        :label="$t('common.search')"
        :placeholder="$t('common.search')"
        @keyup="search"
      >
        <template #prefix>
          <i class="fas fa-search ms-2" />
        </template>
      </el-input>
    </div>
    <div class="col text-end">
      <button
        class="btn mx-3 btn-danger"
        :disabled="loadingDownload"
        type="primary"
        @click="loadingDownload ? undefined: downloadMembersList()"
      >
        
      <span class="indicator-label">
        <span v-if="loadingDownload" class="spinner-border spinner-border-sm align-middle ms-2 mr-2"></span> {{ $t('membersPage.btnDownloadMembers') }}
      </span>
      </button>

      <button
        v-if="checkPermissionUpdate('setting-user-role','setting.user.create')"
        class="btn mx-3"
        @click="openDrawer()"
        style="background: #b31e72; color: white"
      >
        {{ $t('signUpPage.formLabelUser') }}
      </button>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed, ref } from 'vue';
import { Actions } from '@/store/enums/StoreEnums';
import { checkPermissionUpdate } from '@/core/helpers/helps';

export default {
  name: 'members-toolbar',
  setup() {
    const store = useStore();
    const keyword = computed({
      get: () => store.getters.usersFilter.search,
      set: (val) => store.dispatch(Actions.UPDATE_USERS_KEYWORD, val),
    });
    const awaitingSearch = ref();

    function search() {
      if (awaitingSearch.value) {
        clearTimeout(awaitingSearch.value);
        awaitingSearch.value = 0;
      }
      awaitingSearch.value = setTimeout(() => {
        const trimFilter = store.getters.usersFilter;
        store.dispatch(Actions.UPDATE_USERS_PAGE, 1);
        store.dispatch(Actions.GET_USER_LIST, {
          ...trimFilter,
          search: trimFilter.search.replace(/\s+/g, ' ').trim(),
        });
      }, 1000);
    }

    function openDrawer(member, mode = 'add') {
      store.dispatch(Actions.UPDATE_MEMBER_DRAWER_STATE, true);
      store.dispatch(Actions.UPDATE_MEMBER_DRAWER_MODE, mode);
    }

    const loadingDownload = ref(false)
    const downloadMembersList = async () => {
      loadingDownload.value = true
      await store.dispatch(Actions.DOWNLOAD_MEMBERS_LIST)
        .then(() => {
          loadingDownload.value = false
        })
    }

    return {
      openDrawer,
      search,
      checkPermissionUpdate,
      keyword,
      downloadMembersList,
      loadingDownload,
    };
  },
};
</script>

<style scoped></style>
