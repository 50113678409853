<template>
  <div>
    <KTLoader v-if="loaderEnabled" :logo="loaderLogo" />
    <div class="page d-flex flex-row flex-column-fluid">
      <aside-setting
        :lightLogo="themeLightLogo"
        :darkLogo="themeDarkLogo"
      ></aside-setting>
      <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
        <KTHeader :title="pageTitle"></KTHeader>
        <div
          id="kt_content"
          class="content d-flex flex-column flex-column-fluid flex-grow-1"
        >
          <ToolbarSetting v-if="subheaderDisplay" />
          <KTToolbarOut
            v-if="breadCrumbBar"
            :breadcrumbs="breadcrumbs"
            :title="pageTitle"
          ></KTToolbarOut>

            <div id="kt_content_container" class="container-fluid">
              <router-view />
          </div>
        </div>
        <KTFooter />
      </div>
    </div>
  </div>
  <KTScrollTop />
</template>

<script>
import KTLoader from "@/components/Loader.vue";
import AsideSetting from "@/layout/aside/AsideSetting.vue";
import KTHeader from "@/layout/header/Header.vue";
import KTFooter from "@/layout/footer/Footer.vue";
import KTToolbarOut from "@/layout/toolbar/ToolbarOut.vue";
import KTScrollTop from "@/layout/extras/ScrollTop.vue";
import HtmlClass from "@/core/services/LayoutService";

import { useStore } from "vuex";
import { useRoute } from "vue-router";

import {
  toolbarDisplay,
  loaderEnabled,
  contentWidthFluid,
  loaderLogo,
  asideEnabled,
  subheaderDisplay,
  themeLightLogo,
  breadCrumbBar,
  themeDarkLogo,
} from "@/core/helpers/config";
import { computed, onMounted, watch } from "vue";
import {
  DrawerComponent,
  MenuComponent,
  ScrollComponent,
} from "../assets/ts/components";
import { removeModalBackdrop } from "../core/helpers/dom";
import ToolbarSetting from "./toolbar/ToolbarSetting";

export default {
  name: "setting",
  components: {
    AsideSetting,
    ToolbarSetting,
    KTLoader,
    KTHeader,
    KTFooter,
    KTToolbarOut,
    KTScrollTop,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    HtmlClass.init();

    const pageTitle = computed(() => {
      return store.getters.pageTitle;
    });

    const breadcrumbs = computed(() => {
      return store.getters.pageBreadcrumbPath;
    });

    onMounted(() => {
      //check if current user is authenticated
      if (!store.getters.isUserAuthenticated) {
        // router.push({ name: "sign-in" });
      }

      DrawerComponent.bootstrap();
      ScrollComponent.bootstrap();
      DrawerComponent.updateAll();
      ScrollComponent.updateAll();
    });

    watch(
      () => route.path,
      () => {
        MenuComponent.hideDropdowns(undefined);

        DrawerComponent.hideAll();

        // check if current user is authenticated
        if (!store.getters.isUserAuthenticated) {
          // router.push({ name: "sign-in" });
        }

        removeModalBackdrop();
      }
    );

    return {
      toolbarDisplay,
      loaderEnabled,
      contentWidthFluid,
      loaderLogo,
      asideEnabled,
      subheaderDisplay,
      breadCrumbBar,
      pageTitle,
      breadcrumbs,
      themeLightLogo,
      themeDarkLogo,
    };
  },
};
</script>

<style scoped>
  #kt_content {
    padding-top: 70px !important
  }
</style>
