
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { Actions } from '@/store/enums/StoreEnums';
export default {
  name: 'role-and-permission-toolbar',
  setup() {
    const store = useStore();
    const keyword = computed({
      get: () => store.getters.roleKeyword,
      set: (val) => store.dispatch(Actions.UPDATE_MASTER_ROLE_KEYWORD, val),
    });
    onMounted(() => {
      // store.dispatch(Actions.UPDATE_MASTER_ROLE_KEYWORD, '');
    });
    return {
      store,
      Actions,
      keyword,
    };
  },
};
