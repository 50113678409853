
import { defineComponent, onMounted, onUpdated } from "vue";
import { ToggleComponent } from "@/assets/ts/components";
import MenuSetting from "../../layout/aside/MenuSetting.vue";
import { asideTheme } from "@/core/helpers/config";

export default defineComponent({
  name: "aside-setting",
  components: {
    "menu-setting": MenuSetting,
  },
  props: {
    lightLogo: String,
    darkLogo: String,
  },
  setup() {
    onMounted(() => {
      ToggleComponent.reinitialization();
    });

    onUpdated(() => {
      ToggleComponent.bootstrap();
    });

    return {
      asideTheme,
    };
  },
});
